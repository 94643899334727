.contexts {
  margin-bottom: 66px;
  margin-top: 52px;
}
@media (min-width: 960px) {
  .contexts {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 2%;
  }
}

.context {
  background-color: var(--color-white);
  box-shadow: 0 0 15px var(--shadow);
  padding: 18px 3%;
  text-align: center;
  transition: box-shadow 0.3s;
}
.context:hover {
  box-shadow: 8px 8px 15px var(--shadow);
}
@media (max-width: 959px) {
  .context {
    margin-bottom: 24px;
  }
}

.context__icon {
  align-items: center;
  background-color: var(--color-light-blue);
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  height: 50px;
  margin-bottom: 15px;
  width: 50px;
}

.context__title {
  color: var(--color-blue-dark);
  font-size: 1.5rem;
  margin-bottom: 21px;
}

.context__actions {
  margin-top: 21px;
}

.list-content {
  margin-top: 55px;
}

.list-content__item {
  border-bottom: 1px solid var(--color-light-grey);
  font-size: 1.3125rem;
  list-style: none;
  padding-left: 2%;
  padding-right: 2%;
}
@media (min-width: 768px) {
  .list-content__item {
    font-size: 1.5rem;
  }
}

.list-content__link {
  align-items: center;
  display: grid;
  color: inherit;
  grid-template-columns: 11fr 1fr;
  padding-bottom: 21px;
  padding-top: 21px;
  text-decoration: none;
  transition: color 0.3s;
}
.list-content__link:hover, .list-content__link:focus {
  color: var(--color-blue);
}
.list-content__link:hover .list-content__icon, .list-content__link:focus .list-content__icon {
  fill: var(--color-blue);
}

.list-content__icon {
  fill: var(--color-blue-dark);
  transition: fill 0.3s;
}

.list-content__empty {
  display: inline-block;
  padding-bottom: 21px;
  padding-top: 21px;
}

.suggests {
  border-top: 1px solid var(--color-light-grey);
  padding-top: 63px;
}