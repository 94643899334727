.list-content{
  margin-top: 55px;
}

.list-content__item{
  border-bottom: 1px solid var(--color-light-grey);
  font-size: 1.3125rem;
  list-style: none;
  padding-left: 2%;
  padding-right: 2%;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
}

.list-content__link{
  align-items: center;
  display: grid;
  color: inherit;
  grid-template-columns: 11fr 1fr;
  padding-bottom: 21px;
  padding-top: 21px;
  text-decoration: none;
  transition: color .3s;

  &:hover,
  &:focus{
    color: var(--color-blue);

    .list-content__icon{
      fill: var(--color-blue);
    }
  }
}

.list-content__icon{
  fill: var(--color-blue-dark);
  transition: fill .3s;
}

.list-content__empty{
  display: inline-block;
  padding-bottom: 21px;
  padding-top: 21px;
}
