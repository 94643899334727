.contexts{
  margin-bottom: 66px;
  margin-top: 52px;

  @media (min-width: 960px) {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 2%;
  }
}

.context{
  background-color: var(--color-white);
  box-shadow: 0 0 15px var(--shadow);
  padding: 18px 3%;
  text-align: center;
  transition: box-shadow .3s;

  &:hover{
    box-shadow: 8px 8px 15px var(--shadow);
  }

  @media (max-width: 959px) {
    margin-bottom: 24px;
  }
}

.context__icon{
  align-items: center;
  background-color: var(--color-light-blue);
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  height: 50px;
  margin-bottom: 15px;
  width: 50px;
}

.context__title{
  color: var(--color-blue-dark);
  font-size: 1.5rem;
  margin-bottom: 21px;
}

.context__actions{
  margin-top: 21px;
}
